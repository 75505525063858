.App {
  text-align: center;
}
a:link {
  color: white;
  text-decoration: none;
}
a:visited {
  color: white;
  text-decoration: none;
}

a:active {
  color: white;
  text-decoration: none;
}

.hero-section-stop {
  width: 40%;
  padding: 1rem;
}
.hero-section-tombdump {
  width: 40%;
}
.dumperTable {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(63, 59, 92, 0.5) 100%
  ) !important;
  backdrop-filter: blur(14px) !important;
  border-radius: 29px !important;
  min-width: 200px;
  margin: auto;
  max-width: 850px;
  color: white !important;
  border: 2px solid #7955b7;
  padding: 1rem;
  margin: 3em auto;
}

.dumper {
  cursor: pointer;
}

.InfoGraphics {
  text-align: center;
  padding: 2rem;
}
.SupportPegImg {
  width: 80%;
}
.ProfitImg {
  width: 60%;
}
.PrinterBringImg {
  width: 45%;
}
.DumpersTableSection {
  width: 90%;
  margin: auto;
}
.InfoGraphicsSection {
  margin: 0;
  padding: 0;
}
.footerSection {
  width: 100%;
}
@media (max-width: 768px) {
  .hero-section-stop {
    width: 70%;
    padding: 1rem;
  }
  .hero-section-tombdump {
    width: 70%;
  }
  .SupportPegImg {
    width: 95%;
  }
  .ProfitImg {
    width: 95%;
  }
  .PrinterBringImg {
    width: 90%;
  }
}
@media (max-width: 400px) {
  .hero-section-stop {
    width: 70%;
    padding: 1rem;
  }
  .hero-section-tombdump {
    width: 70%;
  }
  .SupportPegImg {
    width: 100%;
  }
  .ProfitImg {
    width: 100%;
  }
  .PrinterBringImg {
    width: 100%;
  }
}
